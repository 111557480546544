@import "definitions";

$dialog_width: 60em;
$dialog_width_wide: 90em;


div.dialog{
    box-sizing: border-box;
    background-color: #fff;
    width: $dialog_width;
    max-width: 95vw;
    padding: $pad_big;
    margin: 0 auto;
    max-height: 100%;
    overflow: auto;

    div.head{
        font-size: $font_size_heading;
        border-bottom: $border;
        margin-bottom: $mar;
        padding: $pad_small 0;
    }
    .close{
        @include sl-icon($fa-var-close); 
        float:right;
        cursor: pointer;
    }
    .arrow{
        @include sl-icon($fa-var-angle-down);
        margin-left: $mar_tiny;
    }
    div.view div.entries_list_body > table td.list_actions {
        .add_entry{
            @include sl-icon($fa-var-plus); 
            color: $theme_color; 
            margin-right: $mar_tiny;
            cursor: pointer;
        }
        .arrow_down{
             @include sl-icon($fa-var-angle-down);
        }
        .view{
               @include sl-icon($fa-var-search);
               color: $theme_color;
               margin-right: $mar_tiny;
               cursor: pointer;
        }
        .edit{
               @include sl-icon($fa-var-pencil);
               color: $theme_color;
               margin-right: $mar_tiny;
               cursor: pointer;
        }
        .delete{
               @include sl-icon($fa-var-trash);
               color: $theme_color;
               cursor: pointer;
        }
     }
 }    
 


div.modal_dialog {
    display:none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    padding: $pad;
    z-index: 999;

    &.wide>div.dialog {
        width: $dialog_width_wide;
    }

    &.full>div.dialog {
        width: 95vw;
    }
}
